import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { Divider } from '@sergeimeza/uikit-next'

import { useAuthContext } from '../../context/AuthContext'

import { Prisma } from '../../interfaces'

import { NavigationItem } from '../Navigation/NavigationItem'
import { VerticalNavigation } from '../Navigation/VerticalNavigation'

const mainDashboardMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
]

const InfluencerMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
]

const BrandMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
  Prisma.UserRole.CLIENT_OWNER,
  Prisma.UserRole.CLIENT_MEMBER,
  Prisma.UserRole.BRAND_AUDITOR,
]

const ProductMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
  Prisma.UserRole.CLIENT_OWNER,
  Prisma.UserRole.CLIENT_MEMBER,
]

const OrientationMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
]

const PreTaskMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.LEGAL,
  Prisma.UserRole.CLIENT_MEMBER,
  Prisma.UserRole.CLIENT_OWNER,
]

const campaignsMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
  Prisma.UserRole.BRAND_AUDITOR,
  Prisma.UserRole.CLIENT_OWNER,
  Prisma.UserRole.CLIENT_MEMBER,
]

const creativesMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
]

const instagramMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
]

const FanMarketingMenuRoles: string[] = [
  Prisma.UserRole.ADMIN,
  Prisma.UserRole.OPERATOR,
  Prisma.UserRole.SALES,
]

const PaymentManagementRoles: string[] = [Prisma.UserRole.ADMIN]

const UgcMenuRoles: string[] = [Prisma.UserRole.ADMIN]

const MainNavigation = () => {
  const { appUser } = useAuthContext()

  const items = useMemo(() => {
    // Logger.logDebug('recomputing items')
    // Logger.logSuccess(`pathName = ${pathName}`)
    // Logger.logDebug(`isDashboard = ${pathName.includes('/app/dashboard')}`)

    const navItems: NavigationItem[] = []

    if (mainDashboardMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'dashboard-menu',
        name: 'ダッシュボード',
        icon: 'ni ni-sound-wave mr-3',
        iconColor: 'text-green',
        to: '#',
        children: [
          {
            id: 'dashboard-main-menu',
            name: '概要',
            to: '/app/dashboard',
          },
          {
            id: 'dashboard-instagram-menu',
            name: 'Instagram',
            to: '/app/dashboard/instagram',
          },
          {
            id: 'dashboard-tiktok-menu',
            name: 'TikTok',
            to: '/app/dashboard/tiktok',
          },
        ],
      })
    }

    if (InfluencerMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'influencer-menu',
        icon: 'ni ni-archive-2 mr-3',
        iconColor: 'text-green',
        name: 'インフルエンサー',
        to: '#',
        children: [
          {
            id: 'influencer-main-menu',
            name: '一覧',
            to: '/app/influencer',
          },
          {
            id: 'influencer-search-menu',
            name: '検索',
            to: '/app/search/influencer',
          },
        ],
      })
    }

    if (BrandMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'brand-menu',
        icon: 'ni ni-shop mr-3',
        iconColor: 'text-green',
        name: 'ブランド',
        to: '/app/brand',
      })
    }

    if (ProductMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'product-menu',
        icon: 'ni ni-box-2 mr-3',
        iconColor: 'text-green',
        name: '商品管理',
        to: '/app/product',
      })
    }

    if (OrientationMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'orientation-menu',
        icon: 'ni ni-tv-2 mr-3',
        iconColor: 'text-green',
        name: 'オリエンテーション',
        to: '/app/orientation',
      })
    }

    if (campaignsMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'campaign-menu',
        icon: 'ni ni-tv-2 mr-3',
        iconColor: 'text-green',
        name: 'キャンペーン',
        to: '/app/campaign',
      })
    }

    if (creativesMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'creatives-menu',
        icon: 'ni ni-tv-2 mr-3',
        iconColor: 'text-green',
        name: 'Creatives',
        to: '/app/admin/creatives',
      })
    }

    if (instagramMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'instagram-menu',
        icon: 'ni ni-tv-2 mr-3',
        iconColor: 'text-green',
        name: 'Instagram',
        to: '#',
        children: [
          {
            id: 'instagram-post-search-menu',
            name: '投稿検索',
            to: '/app/search/instagram-post',
          },
        ],
      })
    }

    if (FanMarketingMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'fan-marketing-menu',
        icon: 'ni ni-bullet-list-67 mr-3',
        iconColor: 'text-green',
        name: 'ファンマーケティング',
        to: '/app/fan-marketing',
      })
    }

    if (PreTaskMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'pre-task-menu',
        icon: 'ni ni-bullet-list-67 mr-3',
        iconColor: 'text-green',
        name: '投稿下書き確認',
        to: '/app/pre-task',
      })
    }

    if (UgcMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'ugc-menu',
        icon: 'ni ni-bullet-list-67 mr-3',
        iconColor: 'text-green',
        name: 'UGC',
        to: '#',
        children: [
          {
            id: 'ugc-store-menu',
            name: 'ストア管理',
            to: '/app/ugc/store',
          },
          {
            id: 'ugc-ig-post-menu',
            name: '投稿管理',
            to: '/app/ugc/instagram-post',
          },
          {
            id: 'ugc-ig-set-menu',
            name: 'UGCセット管理',
            to: '/app/ugc/set',
          },
        ],
      })
    }

    return navItems
  }, [appUser?.role])

  return items ? (
    <div>
      <VerticalNavigation items={items} showIcons />
      <div className='my-2'>
        <Divider />
      </div>
    </div>
  ) : null
}

const AnalyticsNavigation = () => {
  const router = useRouter()
  const pathName = router.pathname
  const { appUser } = useAuthContext()

  const items = useMemo(() => {
    // Logger.logDebug('recomputing items')
    // Logger.logSuccess(`pathName = ${pathName}`)
    // Logger.logDebug(`isDashboard = ${pathName.includes('/app/dashboard')}`)

    const PerformanceMenuRoles: string[] = [
      Prisma.UserRole.ADMIN,
      Prisma.UserRole.OPERATOR,
      Prisma.UserRole.SALES,
    ]

    const TrendMenuRoles: string[] = [
      Prisma.UserRole.ADMIN,
      Prisma.UserRole.OPERATOR,
      Prisma.UserRole.SALES,
    ]

    const navItems: NavigationItem[] = []

    if (PerformanceMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'performance-menu',
        name: '外部サービス連携',
        to: '#',
        icon: 'ni ni-building mr-3',
        iconColor: 'text-green',
        children: [
          {
            id: 'performance-external-link-menu',
            name: '連携設定',
            to: '/app/performance/external-link',
          },
          {
            id: 'performance-analytics-menu',
            name: 'Analytics連携',
            to: '/app/performance/analytics',
          },
        ],
      })
    }

    if (TrendMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'trend-menu',
        icon: 'ni ni-shop mr-3',
        iconColor: 'text-green',
        name: 'トレンド',
        to: '/app/trend',
      })
    }

    return navItems
  }, [appUser?.role, pathName])

  return items ? (
    <div>
      <VerticalNavigation items={items} showIcons />
      <div className='my-2'>
        <Divider />
      </div>
    </div>
  ) : null
}

const OperationsNavigation = () => {
  const { appUser } = useAuthContext()

  const items = useMemo(() => {
    // Logger.logDebug('recomputing items')
    // Logger.logSuccess(`pathName = ${pathName}`)
    // Logger.logDebug(`isDashboard = ${pathName.includes('/app/dashboard')}`)

    const SystemMenuRoles: string[] = [
      Prisma.UserRole.ADMIN,
      Prisma.UserRole.OPERATOR,
      Prisma.UserRole.SALES,
    ]

    const WarehouseMenuRoles: string[] = [
      Prisma.UserRole.ADMIN,
      Prisma.UserRole.OPERATOR,
      Prisma.UserRole.WAREHOUSE,
    ]

    const navItems: NavigationItem[] = []

    if (SystemMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'admin-menu',
        name: 'システム管理',
        to: '#',
        icon: 'ni ni-settings mr-3',
        iconColor: 'text-green',
        children: [
          {
            id: 'admin-client-menu',
            name: 'クライアント管理',
            to: '/app/admin/client',
          },
          {
            id: 'admin-user-menu',
            name: 'ユーザー登録',
            to: '/app/admin/user',
          },
        ],
      })
    }

    if (WarehouseMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'warehouse-menu',
        name: '発送管理',
        to: '#',
        icon: 'ni ni-check-bold mr-3',
        iconColor: 'text-green',
        children: [
          {
            id: 'warehouse-stock-menu',
            name: '在庫管理',
            to: '/app/warehouse/stock',
          },
          {
            id: 'warehouse-inspection-menu',
            name: '検品管理',
            to: '/app/warehouse/inspection',
          },
          {
            id: 'warehouse-delivery-menu',
            name: '発送管理',
            to: '/app/warehouse/delivery',
          },
        ],
      })
    }

    if (PaymentManagementRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'payment-management-menu',
        icon: 'ni ni-credit-card mr-3',
        iconColor: 'text-green',
        name: '支払い管理',
        to: '/app/payment-management',
      })
    }

    return navItems
  }, [appUser?.role])

  return items ? (
    <div>
      <VerticalNavigation items={items} showIcons />
      <div className='my-2'>
        <Divider />
      </div>
    </div>
  ) : null
}

const NotificationsNavigation = () => {
  const router = useRouter()
  const pathName = router.pathname
  const { appUser } = useAuthContext()

  const items = useMemo(() => {
    const NotificationMenuRoles: string[] = [
      Prisma.UserRole.ADMIN,
      Prisma.UserRole.OPERATOR,
    ]

    const navItems: NavigationItem[] = []
    if (NotificationMenuRoles.includes(appUser?.role ?? '')) {
      navItems.push({
        id: 'notification-menu',
        name: 'お知らせ管理',
        to: '#',
        icon: 'ni ni-bell-55 mr-3',
        iconColor: 'text-green',
        children: [
          {
            id: 'notification-cms-menu',
            name: 'CMS内お知らせ管理',
            to: '/app/notification',
          },
          {
            id: 'notification-app-menu',
            name: 'アプリ内お知らせ管理',
            to: '/app/notification/app-notification',
          },
        ],
      })
    }

    return navItems
  }, [appUser?.role, pathName])

  return items ? (
    <div>
      <VerticalNavigation items={items} showIcons />
    </div>
  ) : null
}

const Sidebar = () => {
  // const router = useRouter()
  // const pathName = router.pathname
  const { appUser } = useAuthContext()

  const navigation = useMemo(
    () => (
      <>
        <MainNavigation />
        <AnalyticsNavigation />
        <OperationsNavigation />
        <NotificationsNavigation />
      </>
    ),
    [],
  )

  if (!appUser) return null

  return navigation
}

export { Sidebar }
