/* eslint-disable react/require-default-props */
import { FC, ReactElement } from 'react'

import {
  EuiPageHeaderProps,
  EuiPageSidebarProps,
  EuiPageTemplate,
  EuiPageTemplateProps,
} from '@elastic/eui'

import type { EuiBreadcrumbProps } from '@elastic/eui/src/components/breadcrumbs/breadcrumb'

import { DashboardHeader } from '../components/Headers/dashboard-header'

import { dashboardLayoutStyles } from './dashboard-layout.styles'

type DashboardLayoutProps = EuiPageTemplateProps

interface LemonDashboardLayoutProps {
  button?: ReactElement
  content?: ReactElement
  emptyPromptTitle?: ReactElement
  emptyPromptBody?: ReactElement
  emptyPromptFooter?: ReactElement
  sidebar?: ReactElement
  bottomBar?: ReactElement
  header?: EuiPageHeaderProps
  panelled?: EuiPageTemplateProps['panelled']
  restrictWidth?: EuiPageTemplateProps['restrictWidth']
  bottomBorder?: EuiPageTemplateProps['bottomBorder']
  breadcrumbs?: EuiBreadcrumbProps[]
  // For fullscreen only
  sidebarSticky?: EuiPageSidebarProps['sticky']
  offset?: EuiPageTemplateProps['offset']
  grow?: EuiPageTemplateProps['grow']
}

const DashboardLayout: FC<DashboardLayoutProps & LemonDashboardLayoutProps> = ({
  button = undefined,
  content,
  sidebar,
  emptyPromptTitle,
  emptyPromptBody,
  emptyPromptFooter,
  header,
  panelled,
  bottomBorder = true,
  sidebarSticky,
  restrictWidth,
  bottomBar,
  offset,
  grow,
  breadcrumbs,
  children,
  ...rest
}) => {
  const styles = dashboardLayoutStyles()

  // const restrictWidthClass =
  //   restrictWidth === false ? 'mx-auto w-full' : 'mx-auto w-full max-w-[1400px]'

  return (
    <div css={styles.mainWrapper}>
      <DashboardHeader breadcrumbs={breadcrumbs} />
      <div css={styles.contentWrapper}>
        <EuiPageTemplate
          panelled={panelled}
          restrictWidth={restrictWidth}
          bottomBorder={bottomBorder}
          offset={offset}
          grow={grow}
          // className={restrictWidthClass}
          {...rest}
        >
          {sidebar && (
            <EuiPageTemplate.Sidebar sticky={sidebarSticky}>
              {sidebar}
            </EuiPageTemplate.Sidebar>
          )}
          {header && (
            <EuiPageTemplate.Header
              {...header}
              rightSideItems={button ? [button] : header?.rightSideItems}
            />
          )}
          <EuiPageTemplate.Section color='subdued' grow>
            {content ?? children}
          </EuiPageTemplate.Section>
          {!content && !children && emptyPromptBody && (
            <EuiPageTemplate.EmptyPrompt
              title={emptyPromptTitle}
              footer={emptyPromptFooter}
            >
              {emptyPromptBody}
            </EuiPageTemplate.EmptyPrompt>
          )}
          {bottomBar && (
            <EuiPageTemplate.BottomBar paddingSize='s'>
              {bottomBar}
            </EuiPageTemplate.BottomBar>
          )}
        </EuiPageTemplate>
      </div>
    </div>
  )
}

export type { DashboardLayoutProps }
export { DashboardLayout }
